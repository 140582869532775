import React from 'react';
import {
  SubTitle
} from './menus/Common';

export default class EnvInfo extends React.Component {
  render() {
    return(
      <div id="buildInfo" className="build-info">
        <SubTitle>
          Environment
        </SubTitle>
        {
          Object.keys(process.env).map((type, i) => {
            let fieldFound = false;
            const ignoredFields = [
              'WDS',
              'PUBLIC_URL',
              'REACT',
              'FAST_REFRESH'
            ];
            ignoredFields.map(fieldName => {
              if(type.toUpperCase().startsWith(fieldName)) fieldFound = true;
              return fieldFound;
            })
            if(fieldFound) return false;
            return <div key={type}><label>{type}:</label><span>&nbsp;{process.env[type]}</span></div>
          })
        }
      </div>
    );
  }
}

